<template>
  <div>
    <div class="container-next-step">
      <button class="btn btn-outline-primary" @click="$emit('previousStep')">
        {{ $tc("CAMPAIGN_TARGETING.FORM.PREVIOUS") }}
      </button>
      <button
        class="btn btn-primary ml-3 button-height"
        :disabled="isDisabled"
        @click="submitForm"
      >
        <template v-if="!isLoading">
          {{ $tc("CAMPAIGN_TARGETING.FORM.SUBMIT") }}
        </template>
        <b-spinner v-if="isLoading" variant="white"></b-spinner>
      </button>
    </div>

    <b-alert :show="isShowErrors" dismissible variant="danger" class="mt-2"
      >{{ $tc("ERROR_500") }}
    </b-alert>
    <b-row>
      <b-col cols="12" md="6" sm="12">
        <TagSelect
          :listOptions="listCategories"
          :label="$tc('CAMPAIGN_TARGETING.FORM.CATEGORIES_LABEL')"
          buttonText="Choose categories"
          buttonIcon="mdi-target"
          fieldName="name"
          textTootlip="Test"
          variant="success"
          :preSelectedElement="categoriesSelected"
          @change="changeCategory"
        />
      </b-col>

      <b-col cols="12" md="6" sm="12">
        <TagSelect
          :listOptions="listLanguages"
          :label="$tc('CAMPAIGN_TARGETING.FORM.LANGUAGE_LABEL')"
          buttonText="Choose languages"
          buttonIcon="mdi-flag"
          fieldName="name"
          textTootlip="Test"
          variant="success"
          :preSelectedElement="languagesSelected"
          @change="changeLanguages"
        />
      </b-col>
    </b-row>

    <b-row v-if="isAdmin">
      <b-col cols="12" md="6" sm="12">
        <TagSelect
          :listOptions="listDomains"
          :label="$tc('CAMPAIGN_TARGETING.FORM.PUBLISHER_LABEL')"
          buttonText="Choose domains"
          buttonIcon="mdi-domain"
          fieldName="name"
          variant="danger"
          isPaginate
          :preSelectedElement="excludeDomainsSelected"
          :isLoading="isLoadingDomain"
          :currentPage="currentPageDomains"
          :lastPage="lastPageDomains"
          @click="checkDomain"
          @change="changeDomains"
          @changePage="changePageDomains"
          @clear="clearSelectedDomains"
        />
      </b-col>

      <b-col cols="12" md="6" sm="12">
        <TagSelect
          :listOptions="listPrograms"
          :label="$tc('CAMPAIGN_TARGETING.FORM.PROGRAM_LABEL')"
          buttonText="Choose shows"
          buttonIcon="mdi-podcast"
          fieldName="name"
          variant="danger"
          :preSelectedElement="excludeProgramsSelected"
          isPaginate
          :isLoading="isLoadingPrograms"
          :currentPage="currentPagePrograms"
          :lastPage="lastPagePrograms"
          @change="changePrograms"
          @changePage="changePagePodcasts"
          @clear="clearSelectedPrograms"
        />
      </b-col>
    </b-row>

    <div class="container-next-step mt-10">
      <button class="btn btn-outline-primary" @click="$emit('previousStep')">
        {{ $tc("CAMPAIGN_TARGETING.FORM.PREVIOUS") }}
      </button>
      <button
        class="btn btn-primary ml-3 button-height"
        :disabled="isDisabled"
        @click="submitForm"
      >
        <template v-if="!isLoading">
          {{ $tc("CAMPAIGN_TARGETING.FORM.SUBMIT") }}
        </template>
        <b-spinner v-if="isLoading" variant="white"></b-spinner>
      </button>
    </div>
  </div>
</template>

<script>
import { getTagsType } from "@/api/categories";
import { getProgramsV2 } from "@/api/programs/getters";
import TagSelect from "@/components/TagSelectV2";
import { getDomainsV2 } from "@/api/networks/getters";
import { createCampaignsTags } from "@/api/campaigns/manage/setters";
import { ENTITY_NAME_IN_URI } from "@/api/config/api2.0";
import { updateBlacklistDomains } from "@/api/campaigns/manage/setters";
import { updateBlacklistPrograms } from "@/api/programs/setters";

export default {
  components: {
    TagSelect
  },

  props: {
    isAdmin: {
      type: Boolean,
      required: false
    },
    campaign: {
      type: Object,
      reuqired: false
    },
    categoriesSelected: {
      type: Array,
      reuqired: false
    },
    languagesSelected: {
      type: Array,
      required: false
    },
    blacklistDomainsSelected: {
      type: Array,
      required: false
    },
    blacklistProgramsSelected: {
      type: Array,
      required: false
    }
  },

  data() {
    return {
      categories: [],
      languages: [],
      excludeDomains: [],
      excludeDomainsSelected: [],
      excludePrograms: [],
      excludeProgramsSelected: [],
      isLoading: false,
      listPrograms: [],
      programsPreselected: [],
      listDomains: [],
      domainsPreselected: [],
      listLanguages: [],
      listCategories: [],
      categoriesPreselected: [],
      currentPageDomains: 1,
      lastPageDomains: 1,
      currentPagePrograms: 1,
      lastPagePrograms: 0,
      isLoadingPrograms: false,
      isLoadingDomain: false,
      isShowErrors: false
    };
  },

  computed: {
    isDisabled() {
      return this.isLoading;
    }
  },

  mounted() {
    this.loadCategories();
    this.loadLanguages();
    this.loadPrograms();
    this.loadDomains();
    this.setAllCategories();
  },

  methods: {
    async loadCategories() {
      try {
        const response = await getTagsType(
          this.axios,
          ENTITY_NAME_IN_URI.CAMPAIGNS.TAGS.iab.name
        );
        this.listCategories = response.data;
      } catch (error) {
        console.error("---ERROR-CATEGORIES---");
        console.error(error);
        this.listCategories = [];
      }
    },

    changeCategory(categories) {
      this.categories = categories;
    },

    async loadLanguages() {
      try {
        const response = await getTagsType(
          this.axios,
          ENTITY_NAME_IN_URI.CAMPAIGNS.TAGS.languages.name
        );
        this.listLanguages = response.data;
      } catch (error) {
        console.error("---ERROR-LANGUAGES");
        console.error(error);
        this.listLanguages = [];
      }
    },

    changeLanguages(languages) {
      this.languages = languages;
    },

    setAllCategories() {
      let categoriesPreselected = [];

      this.listCategories.forEach(element => {
        categoriesPreselected.push(element.text);
      });

      this.categoriesPreselected = [...categoriesPreselected];
      this.categories = [...categoriesPreselected];
    },

    async loadDomains() {
      this.isLoadingDomain = true;

      try {
        const filter = {
          page: this.currentPageDomains
        };
        const response = await getDomainsV2(filter, this.axios);
        this.lastPageDomains = response.last_page;
        this.listDomains = [...this.listDomains.concat(response.data)];
      } catch (error) {
        console.error("---ERROR-DOMAINS---");
        console.error(error);
        this.listDomains = [];
      }

      this.isLoadingDomain = false;
    },

    changePageDomains() {
      this.currentPageDomains = ++this.currentPageDomains;
      this.loadDomains();
    },

    changeDomains(domains) {
      this.excludeDomains = domains;
    },

    clearSelectedDomains() {
      this.excludeDomains = [];
      this.excludeDomainsSelected = [];
    },

    async loadPrograms() {
      this.isLoadingPrograms = true;

      try {
        const filter = {
          page: this.currentPagePrograms
        };

        const response = await getProgramsV2(filter, this.axios);
        this.lastPagePrograms = response.last_page;
        this.listPrograms = [...this.listPrograms].concat(response.data);
      } catch (error) {
        console.error("---ERROR-PROGRAMS---");
        console.error(error);
        this.listPrograms = [];
      }

      this.isLoadingPrograms = false;
    },

    changePagePodcasts() {
      this.currentPagePrograms = ++this.currentPagePrograms;
      this.loadPrograms();
    },

    changePrograms(programs) {
      this.excludePrograms = programs;
    },

    clearSelectedPrograms() {
      this.excludePrograms = [];
      this.excludeProgramsSelected = [];
    },

    checkDomain(domain) {
      if (domain.programDomains.length > 0) {
        domain.programDomains.forEach(program => {
          if (
            this.excludeProgramsSelected.find(item => {
              return item.id === program.program.id;
            }) === undefined
          ) {
            this.excludeProgramsSelected.push(program.program);
          }

          if (
            this.excludePrograms.find(
              item => item.id === program.program.id
            ) === undefined
          ) {
            this.excludePrograms.push(program.program);
          }
        });
      }
    },

    prepepareRequest(data) {
      let body = [];

      data.forEach(element => {
        body.push(element.id);
      });

      return body;
    },

    async updateBlacklistDomains() {
      const bodyExcludeDomains = this.prepepareRequest(this.excludeDomains);
      await updateBlacklistDomains(
        bodyExcludeDomains,
        this.campaign.id,
        this.axios
      );
    },

    async updateBlacklistPrograms() {
      const bodyExcludePrograms = this.prepepareRequest(this.excludePrograms);
      await updateBlacklistPrograms(
        bodyExcludePrograms,
        this.campaign.id,
        this.axios
      );
    },

    async sendTags() {
      let bodyTags = [];
      bodyTags = bodyTags.concat(this.prepepareRequest(this.categories));
      bodyTags = bodyTags.concat(this.prepepareRequest(this.languages));
      await createCampaignsTags(bodyTags, this.campaign.id, this.axios);
    },

    async submitForm() {
      this.isLoading = true;
      try {
        await this.updateBlacklistPrograms();
        await this.updateBlacklistDomains();
        await this.sendTags();

        this.$emit("submitForm", {
          categories: this.categories,
          languages: this.languages,
          excludeDomains: this.excludeDomains,
          excludePrograms: this.excludePrograms
        });
      } catch (error) {
        console.error("---ERROR-TARGETING---");
        console.error(error);
        this.isShowErrors = true;
      }

      this.isLoading = false;
    }
  },

  watch: {
    categoriesSelected() {
      this.categories = this.categoriesSelected;
    },

    languagesSelected() {
      this.languages = this.languagesSelected;
    },

    blacklistDomainsSelected() {
      this.excludeDomainsSelected = this.blacklistDomainsSelected;
    },

    blacklistProgramsSelected() {
      this.excludeProgramsSelected = this.blacklistProgramsSelected;
    }
  }
};
</script>

<style scoped>
.container-next-step {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
