import { ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2 } from "@/api/config/api2.0";
import {
  post as postApi2,
  update as updateApi2,
  remove as removeApi2
} from "@/api/getApi2.0";

export const createCampaignSettings = (body, axios) =>
  postApi2(
    {
      path: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.CAMPAIGNS_ORDERS.path
    },
    {},
    body,
    axios
  );

export const updateCampaignSetttings = (body, campaignId, axios) =>
  updateApi2(
    {
      path: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.CAMPAIGNS_ORDERS.path,
      ressources: [
        {
          name: campaignId,
          value: ""
        }
      ]
    },
    body,
    axios
  );

export const createCampaignsTags = (body, campaignId, axios) =>
  updateApi2(
    {
      path: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.CAMPAIGNS_ORDERS.path,
      ressources: [
        {
          name: campaignId,
          value: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.CAMPAIGNS_ORDERS.name
        }
      ]
    },
    body,
    axios
  );

export const createCampaignNotifications = (body, campaignId, axios) =>
  updateApi2(
    {
      path: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.NOTIFICATIONS.path,
      ressources: [
        {
          name: campaignId
        },
        {
          name: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.NOTIFICATIONS.name
        }
      ]
    },
    body,
    axios
  );

export const deleteCampaignV2 = (campaignId, axios) =>
  removeApi2(
    {
      path: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.CAMPAIGNS_ORDERS.path,
      ressources: [
        {
          name: campaignId
        }
      ]
    },
    {},
    axios
  );

export const duplicateCampaignV2 = (campaignId, newName, axios) =>
  postApi2(
    {
      path: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.CAMPAIGNS_DUPLICATE.path,
      ressources: [
        {
          name: campaignId
        },
        {
          name: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.CAMPAIGNS_DUPLICATE.name
        }
      ]
    },
    {},
    { name: newName },
    axios
  );

export const updateBlacklistDomains = (body, campaignId, axios) =>
  updateApi2(
    {
      path: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.CAMPAIGNS_ORDERS.path,
      ressources: [
        {
          name: campaignId,
          value: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.DOMAINS.blacklist
        },
        {
          name: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.DOMAINS.path
        }
      ]
    },
    body,
    axios
  );

export const updateCampaignV2 = (body, campaignId, axios) =>
  updateApi2(
    {
      path: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.CAMPAIGNS_ORDERS.path,
      ressources: [
        {
          name: campaignId
        }
      ]
    },
    body,
    axios
  );
