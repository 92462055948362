<template>
  <div>
    <div class="d-flex w-100 mb-3">
      <div
        v-if="!hasSelectedEspiode"
        class="alert alert-solid-warning align-items-center w-100 mb-0"
      >
        <i class="fa fa-info-circle mr-3" />
        {{ $tc("CAMPAIGN_LISTING_EPISODE.SELECT_EPISODE") }}
      </div>

      <div class="container-next-step">
        <button
          class="btn btn-outline-primary ml-3"
          @click="$emit('previousStep')"
        >
          {{ $tc("CAMPAIGN_LISTING_EPISODE.PREVIOUS") }}
        </button>
        <button
          class="btn btn-primary ml-3 button-height text-white"
          :disabled="isDisabled"
          @click="submitForm"
        >
          <template v-if="!isLoading">
            {{ $tc("CAMPAIGN_LISTING_EPISODE.SUBMIT") }}
          </template>
          <b-spinner v-if="isLoading" variant="white"></b-spinner>
        </button>
      </div>
    </div>

    <div class="d-flex flex-column">
      <ListingEpisode
        :programId="programId"
        :selectEpisodes="selectEpisodes"
        :item="podcasts"
        @itemsSelected="episodeSelected"
      />
    </div>

    <div class="container-next-step mt-10">
      <button
        class="btn btn-outline-primary ml-3"
        @click="$emit('previousStep')"
      >
        {{ $tc("CAMPAIGN_LISTING_EPISODE.PREVIOUS") }}
      </button>
      <button
        class="btn btn-primary ml-3 button-height text-white"
        :disabled="isDisabled"
        @click="submitForm"
      >
        <template v-if="!isLoading">
          {{ $tc("CAMPAIGN_LISTING_EPISODE.SUBMIT") }}
        </template>
        <b-spinner v-if="isLoading" variant="white"></b-spinner>
      </button>
    </div>
  </div>
</template>

<script>
import ListingEpisode from "@/components/sponsoring/ListingEpisode";

export default {
  components: {
    ListingEpisode
  },

  props: {
    programId: {
      type: String,
      required: true
    },
    podcasts: {
      type: Array,
      required: true
    },
    selectEpisodes: {
      type: Array,
      required: false
    }
  },

  data() {
    return {
      episodes: [],
      itemLine: {},
      isLoading: false
    };
  },

  computed: {
    getNbrEpisodes() {
      return this.episodes.length;
    },

    hasSelectedEspiode() {
      return this.getNbrEpisodes > 0;
    },

    isDisabled() {
      return !this.hasSelectedEspiode || this.isLoading;
    }
  },

  methods: {
    episodeSelected(episodes) {
      this.episodes = episodes;
    },

    submitForm() {
      if (this.hasSelectedEspiode) {
        this.isLoading = true;
        this.items = [];
        this.$emit("submitEpisodes", this.episodes);
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.container-next-step {
  display: flex;
  justify-content: flex-end;
}
</style>
