<template>
  <div class="container-listing-episode">
    <b-input-group class="mt-3">
      <b-input-group-prepend is-text>
        <v-icon>mdi-magnify</v-icon>
      </b-input-group-prepend>
      <b-form-input
        id="filter-input"
        v-model="filter"
        type="search"
        placeholder="Search episode"
      ></b-form-input>
    </b-input-group>

    <b-table
      id="table-episodes"
      :fields="headers"
      :items="itemsFilter"
      hover
      striped
      :per-page="perPage"
      show-empty
      :busy="isLoading"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-3">Loading...</strong>
        </div>
      </template>

      <template #head(checkbox)>
        <b-form-checkbox
          v-model="checkboxAll"
          class="align-middle"
          @change="checkboxCheckedAll()"
        ></b-form-checkbox>
      </template>

      <template #emptyfiltered="scope">
        <div class="d-flex justify-content-center">
          <span class="font-weight-bold">{{ scope.emptyFilteredText }}</span>
        </div>
      </template>

      <template #cell(checkbox)="row">
        <b-form-checkbox
          v-model="row.item.selected"
          class="align-middle"
          @change="checkboxChecked(row.item)"
        ></b-form-checkbox>
      </template>

      <template #cell(title)="row">
        {{ cutSentence(row.item.title, 100) }}
      </template>
    </b-table>

    <div class="d-flex justify-content-center mt-3">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="perPage"
        aria-controls="table-episodes"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { getEpisodeFromProgram } from "@/api/podcasts/getters";

export default {
  props: {
    programId: {
      type: String,
      required: true
    },
    item: {
      type: Array,
      required: true
    },
    selectEpisodes: {
      type: Array,
      required: false
    }
  },

  data() {
    return {
      podcast: [],
      items: [],
      itemsSelected: [],
      elements: {},
      totalItems: 0,
      checkboxAll: false,
      currentPage: 1,
      isLoading: false,
      perPage: 70,
      filter: "",
      headers: [
        {
          text: "",
          key: "checkbox"
        },
        {
          text: "Episodes",
          key: "title"
        }
      ]
    };
  },

  computed: {
    itemsFilter() {
      let itemsFilter = this.items;
      if (this.filter) {
        itemsFilter = this.items.filter(element =>
          element.title.toUpperCase().includes(this.filter.toUpperCase())
        );
      }

      return itemsFilter;
    }
  },

  async mounted() {
    await this.load();

    if (this.selectEpisodes.length > 0) {
      this.alreadySelectEpisode();
    }
  },

  methods: {
    async load() {
      this.loading = true;
      this.podcastNotFound = false;
      this.isLoading = true;

      try {
        const response = await getEpisodeFromProgram(
          this.programId,
          this.currentPage,
          this.perPage,
          this.axios
        );
        this.items = response.data;
        this.currentPage = response.current_page;
        this.totalItems = response.total_items;
        this.perPage = response.items_per_page;
      } catch (error) {
        console.error("---ERROR-LIST-EPISODES");
        console.error(error);
      }

      this.isLoading = false;
    },

    alreadySelectEpisode() {
      this.selectEpisodes.forEach(itemLine => {
        const findEpisode = this.items.find(item => {
          const id = itemLine.episode ? itemLine.episode.id : itemLine.id;
          return id === item.id;
        });

        if (findEpisode) {
          this.$set(findEpisode, "selected", true);
        } else {
          console.error("cannot found ", itemLine, findEpisode);
        }
        itemLine.selected = true;
        this.checkboxChecked(itemLine);
      });

      if (this.selectEpisodes.length === this.items.length) {
        this.checkboxAll = true;
      }
    },

    checkboxChecked(item) {
      if (item.selected) {
        this.itemsSelected.push(item);
      } else {
        const findIndex = this.itemsSelected.findIndex(element =>
          element.episode
            ? element.episode.id === item.id
            : element.id === item.id
        );

        this.itemsSelected.splice(findIndex, 1);
      }

      this.$emit("itemsSelected", this.itemsSelected);
    },

    checkboxCheckedAll() {
      let items = this.items;
      let status = this.checkboxAll ? true : false;

      const rows = items.map(row => {
        return {
          ...row,
          selected: status
        };
      });

      this.items = rows;

      if (status) {
        this.itemsSelected = this.items;
      } else {
        this.itemsSelected = [];
      }

      this.$emit("itemsSelected", this.itemsSelected);
    },

    cutSentence(sentence, len) {
      let cutS = sentence.substring(0, len);
      if (40 < sentence.length && this.cutTitle) {
        cutS += "...";
      }
      return cutS;
    }
  },

  watch: {
    currentPage() {
      this.load();
    }
  }
};
</script>

<style scoped>
.container-listing-episode {
  width: 100%;
}
</style>
