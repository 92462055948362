<template>
  <div class="kt-container" :key="$route.params.id">
    <div class="bb-card">
      <div class="wizard justify-content-center justify-content-sm-start w-100">
        <div
          v-for="(item, index) in wizardMenu"
          :key="`wizard_${index}`"
          class="wizard-label test"
          :class="{ 'wizard-label-active': index === currentStep }"
        >
          <div class="d-flex flex-row align-items-end">
            <div class="d-flex flex-row w-100">
              <h3 class="wizard-title">
                <span class="wizard-step">{{ index + 1 }}</span> {{ item.text }}
              </h3>
              <v-icon
                v-if="item.done && index !== currentStep"
                :color="item.done ? 'green' : '#B5B5C3'"
                >mdi-check</v-icon
              >
            </div>
          </div>
        </div>
      </div>

      <div v-if="hasCampaign">
        <div class="w-100 d-flex justify-content-center">
          <div class="border rounded py-5 w-100 px-5 mx-sm-12">
            <CampaignHeader
              :campaignOrder="campaign"
              :categories="categories"
              :languages="languages"
              :budgetSpent="remainingBudget"
            />
          </div>
        </div>
      </div>

      <div class="d-flex flex-column justify-content-center py-10 px-sm-12">
        <div v-show="currentStep === 0" class="w-100">
          <CampaignSettings
            :isAdmin="isAdmin"
            :user="user"
            :campaign="campaign"
            @submitForm="submitCampaignSettings"
          />
        </div>

        <div v-if="currentStep === 1">
          <ListingEpisodeSponsoring
            :programId="programId"
            :podcasts="podcasts"
            :selectEpisodes="episodes"
            @submitEpisodes="submitEpisodes"
            @previousStep="previousStep"
          />
        </div>

        <div v-show="currentStep === 2">
          <ConfigEpisode
            :items="episodes"
            :budget="parseInt(campaign.budget)"
            :isAdmin="isAdmin"
            :campaign="campaign"
            :budgetSpent="remainingBudget"
            @applyAll="applyAll"
            @deleteEpisode="deleteEpisode"
            @duplicateEpisode="duplicateEpisode"
            @submitForm="submitConfigEpisodes"
            @previousStep="previousStep"
            @budgetSpent="changeBudgetSpent"
          />
        </div>

        <div v-show="currentStep === 3">
          <CampaignTargeting
            :isAdmin="isAdmin"
            :campaign="campaign"
            :categoriesSelected="categories"
            :languagesSelected="languages"
            :blacklistDomainsSelected="blacklistDomains"
            :blacklistProgramsSelected="blacklistPrograms"
            @submitForm="submitFormCampaignTargeting"
            @previousStep="previousStep"
          />
        </div>

        <b-modal
          ref="modal-notifications"
          title="Campaigns Notifications"
          hide-header-close
          hide-footer
          no-close-on-backdrop
        >
          <CampaignNotifications
            :user="user"
            :campaign="campaign"
            @cancel="closeModalNotifications"
            @submitForm="submitCampaignNotifications"
          />
        </b-modal>

        <div v-if="currentStep === 4">
          <div class="d-flex flex-column align-items-center">
            <h1>{{ $tc("EDIT_V2.CONGRATULATIONS") }}</h1>
            <span
              >{{ $tc("EDIT_V2.INSTRUCTIONS") }}
              <span class="text-primary font-weight-bold">{{
                countdownNumber
              }}</span>
              {{ $tc("EDIT_V2.INSTRUCTIONS_TIMER") }}
              <router-link
                :to="{ name: 'adstudio_delivery_orders' }"
                class="text-decoration-underline"
                >{{ $tc("EDIT_V2.CLICK_HERE") }}</router-link
              ></span
            >
            <b-spinner
              variant="primary"
              label="Spinning"
              class="mt-2"
            ></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CampaignSettings from "@/components/sponsoring/CampaignSettings";
import ListingEpisodeSponsoring from "@/components/sponsoring/ListingEpisodeSponsoring";
import { getCampaignV2 } from "@/api/campaigns/manage/getters";
import ConfigEpisode from "@/components/sponsoring/ConfigEpisode";
import CampaignTargeting from "@/components/sponsoring/CampaignTargeting";
import CampaignNotifications from "@/components/sponsoring/CampaignNotifications";
import { getItemsLineFromSponsoring } from "@/api/campaigns/lineItems/getters";
import CampaignHeader from "@/components/sponsoring/CampaignHeader";

export default {
  name: "EditV2",

  components: {
    CampaignSettings,
    ListingEpisodeSponsoring,
    ConfigEpisode,
    CampaignTargeting,
    CampaignNotifications,
    CampaignHeader
  },

  data() {
    return {
      episodes: [],
      currentStep: 0,
      campaign: {
        feed: "",
        budget: null,
        tags: []
      },
      date: "",
      episodesSortByStartDate: [],
      episodeStart: {},
      episodeEnd: {},
      isLoading: false,
      remainingBudget: 0,
      email: "",
      programId: "",
      podcasts: [],
      countdownNumber: 5,
      wizardMenu: [
        { text: "Campaign Settings", done: false },
        { text: "Campaign Selection", done: false },
        { text: "Campaign Planning", done: false },
        { text: "Campaign Targeting", done: false }
      ],
      categories: [],
      languages: [],
      listCategories: [],
      blacklistDomains: [],
      blacklistPrograms: []
    };
  },

  computed: {
    ...mapGetters({
      user: "currentUser",
      isAdmin: "isAdmin"
    }),

    hasCampaign() {
      return this.campaign.name;
    },

    hasCategories() {
      return this.categories.length > 0;
    },

    hasLanguages() {
      return this.getLangTagsSelected.length > 0;
    },

    getIabTagsSelected() {
      let tags = [];

      if (this.campaign.tags.length > 0) {
        tags = this.campaign.tags.filter(
          element => element.tagType.name === "iab_type"
        );
      }

      return tags;
    },

    getLangTagsSelected() {
      let tags = [];

      if (this.campaign.tags.length > 0) {
        tags = this.campaign.tags.filter(
          element => element.tagType.name === "lang_type"
        );
      }

      return tags;
    },

    budgetSpent() {
      return this.remainingBudget;
    },

    company() {
      return this.campaign.company;
    },

    getVariantBudget() {
      let variant = "success";

      if (this.budgetSpent > this.campaign.budget) {
        variant = "danger";
      }

      return variant;
    }
  },

  beforeMount() {
    const campaignId = this.$route.params.id;

    if (campaignId) {
      this.load();
    }
  },

  methods: {
    load() {
      const campaignId = this.$route.params.id;

      this.loadCampaign(campaignId);
      this.loadItemLine(campaignId);

      this.email = this.user.email;
    },

    goToStep(index) {
      const currentStep = this.wizardMenu[this.currentStep];
      currentStep.isActive = false;

      this.wizardMenu[index].isActive = true;
      this.currentStep = index;
    },

    goNextStep() {
      this.goToStep(this.currentStep + 1);
    },

    previousStep() {
      this.goToStep(this.currentStep - 1);
      this.undoneStep();
    },

    doneStep() {
      this.wizardMenu[this.currentStep].done = true;
    },

    undoneStep() {
      this.wizardMenu[this.currentStep].done = false;
    },

    async loadCampaign(campaignId) {
      try {
        const campaign = await getCampaignV2(campaignId, this.axios);
        this.programId = campaign.program.id;
        this.campaign = campaign;

        this.categories = this.getIabTagsSelected;
        this.languages = this.getLangTagsSelected;
        this.blacklistDomains = this.campaign.domainsBlacklist;
        this.blacklistPrograms = this.campaign.programsBlacklist;
      } catch (error) {
        console.error("---ERROR-CAMPAIGN---");
        console.error(error);
      }
    },

    async loadItemLine(campaignId) {
      try {
        const itemsLine = await getItemsLineFromSponsoring(
          campaignId,
          {},
          this.axios
        );
        this.episodes = itemsLine;
      } catch (error) {
        console.error("---ERROR-ITEMS-LINE---");
        console.error(error);
      }
    },

    checkEmail(user) {
      const regexEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
      let state = false;

      if (regexEmail.test(user.email)) {
        state = true;
      }

      user.state = state;
    },

    submitCampaignSettings(campaign) {
      this.campaign = campaign;
      this.programId = campaign.program.id;
      this.doneStep();
      this.goNextStep();
    },

    submitEpisodes(episodes) {
      episodes.forEach(element => {
        if (!element.cpl) {
          element.cpl = this.campaign.cpl;
        }
        if (!element.priority) {
          element.priority = 1;
        }
        if (!element.artificialBudgetRatio) {
          element.artificialBudgetRatio = 0;
        }
        if (!element.episode) {
          element.episode = {
            title: element.title,
            id: element.id
          };
        }
      });

      this.episodes = [...episodes];
      this.doneStep();
      this.goNextStep();
    },

    applyAll({ field, data }) {
      this.episodes.forEach(element => {
        if (field === "startAt") {
          element.hasChangeStartAt = true;
        }
        if (!element.startAt) {
          element[field] = data;
        } else if (this.canChangeElement(element.startAt)) {
          element[field] = data;
        }
      });

      let episodes = [...this.episodes];
      this.episodes = [];
      this.episodes = [...episodes];
    },

    canChangeElement(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      date = new Date(date);
      date.setHours(0, 0, 0, 0);

      return date >= today;
    },

    deleteEpisode(index) {
      this.episodes.splice(index, 1);
    },

    changeBudgetSpent(remainingBudget) {
      this.remainingBudget = remainingBudget;
    },

    closeModalNotifications() {
      this.$refs["modal-notifications"].hide();
    },

    submitCampaignNotifications() {
      this.closeModalNotifications();
      this.doneStep();
      this.currentStep = 4;
      this.countdown();
    },

    submitConfigEpisodes(episodes) {
      this.doneStep();
      this.goNextStep();
      this.episodes = episodes;
    },

    submitFormCampaignTargeting({ categories, languages }) {
      this.categories = categories;
      this.languages = languages;
      this.doneStep();
      this.$refs["modal-notifications"].show();
    },

    duplicateEpisode(episode) {
      this.episodes.push(episode);
    },

    countdown() {
      if (this.countdownNumber > 0) {
        setTimeout(() => {
          this.countdownNumber -= 1;
          this.countdown();
        }, 1000);
      } else {
        this.$router.push({ name: "adstudio_delivery_orders" });
        this.countdown;
      }
    }
  },

  watch: {
    "$route.params.id"() {
      this.campaign = {};
      this.episodes = [];
      this.currentStep = 0;
    }
  }
};
</script>

<style scoped>
.wizard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.wizard-label {
  display: flex;
  padding-bottom: 0.5rem;
  border-bottom: 4px solid #b5b5c3;
  color: #b5b5c3;
  width: 205px;
  margin: 2rem 0.5rem;
}

.wizard-label-active {
  color: #3699ff;
  border-bottom-color: #3699ff;
}

.wizard-step {
  font-size: 2rem;
  margin-right: 0.5rem;
  font-weight: 600;
}

.wizard-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0;
}

.bb-body-modal {
  padding: 0px !important;
}

.bb-modal-footer {
  padding: 13px;
}

.bb-datepicker {
  width: 170px;
  font-size: 12px;
  height: 31px;
}

.bb-font-size {
  font-size: 12px;
  height: 31px;
}

.container-next-step {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.bb-card {
  background: #fff;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
  border-radius: 0.75rem;
  padding: 1.25rem;
}
</style>
