import {
  post,
  update,
  remove,
  postWithCompleteResponse
} from "@/api/getApi2.0";
import { ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2 } from "@/api/config/api2.0";

export const updateBlacklistPrograms = (body, campaignId, axios) =>
  update(
    {
      path: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.CAMPAIGNS_ORDERS.path,
      ressources: [
        {
          name: campaignId,
          value: ENTITY_NAME_IN_URI_V2.PROGRAMS.blacklist
        },
        {
          name: ENTITY_NAME_IN_URI_V2.PROGRAMS.path
        }
      ]
    },
    body,
    axios
  );

export const addEpisodesToProgram = (body, programId, axios) =>
  post(
    {
      path: ENTITY_NAME_IN_URI_V2.EPISODES_PROGRAMS.path,
      ressources: [
        {
          name: programId,
          value: ENTITY_NAME_IN_URI_V2.EPISODES_PROGRAMS.name
        }
      ]
    },
    {},
    body,
    axios
  );

export const deleteProgram = (body, programId, axios) =>
  remove(
    {
      path: ENTITY_NAME_IN_URI_V2.PROGRAMS.path,
      ressources: [
        {
          name: programId
        }
      ]
    },
    body,
    axios
  );

export const create = (body, axios, timeout) =>
  postWithCompleteResponse(
    {
      path: ENTITY_NAME_IN_URI_V2.PROGRAMS.path
    },
    {},
    body,
    axios,
    timeout
  );

export const cleanImages = (programId, axios) =>
  remove(
    {
      path: ENTITY_NAME_IN_URI_V2.PROGRAMS.path,
      ressources: [
        {
          name: programId,
          value: ENTITY_NAME_IN_URI_V2.PROGRAMS.clean
        }
      ]
    },
    {},
    axios
  );
