export const fields = [
  {
    key: "checkbox",
    sortable: false,
    sortDirection: "desc",
    class: "text-center",
    tdClass: "text-center p-0"
  },
  {
    key: "title",
    label: "Title",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left w-100"
  }
];

export const priorityList = [
  {
    text: "Standard",
    value: 1,
    icon: {
      img: "mdi-minus",
      color: "blue"
    },
    descriptionKey: "SPONSORING_EDIT.BOOSTERS_LIST.NORMAL"
  },
  {
    text: "High",
    value: 5,
    icon: {
      img: "mdi-chevron-up",
      color: "orange"
    },
    descriptionKey: "SPONSORING_EDIT.BOOSTERS_LIST.HIGH"
  },
  {
    text: "Highest",
    value: 10,
    icon: {
      img: "mdi-chevron-double-up",
      color: "red"
    },
    descriptionKey: "SPONSORING_EDIT.BOOSTERS_LIST.HIGHEST"
  }
];

export const options = [
  { value: null, text: "Please select an objectif type" },
  { value: "impression", text: "Impressions" },
  { value: "play", text: "Plays" },
  { value: "click", text: "Clicks" }
];

export const priorityOptions = [
  {
    text: "Normal",
    value: 1
  },
  {
    text: "High",
    value: 5
  },
  {
    text: "Highest",
    value: 10
  }
];

export const budgetOptions = [
  { text: "500€", value: 500 },
  { text: "1000€", value: 1000 },
  { text: "2000€", value: 2000 }
];

export const statusOptions = [
  { value: 0, text: "Pending" },
  { value: 1, text: "Started" }
];

export const episodesSelectionOptions = [
  { text: "Last", value: "last" },
  { text: "All", value: "all" },
  { text: "Manual selection", value: "manual" }
];

export const artificialBudgetRatioOptions = [
  { value: -0.75, text: "-75%" },
  { value: -0.5, text: "-50%" },
  { value: -0.25, text: "-25%" },
  { value: 0, text: "0%" },
  { value: 0.02, text: "+2%" },
  { value: 0.05, text: "+5%" },
  { value: 0.1, text: "+10%" },
  { value: 0.15, text: "+15%" },
  { value: 0.12, text: "+25%" },
  { value: 0.5, text: "+50%" }
];
