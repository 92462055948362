import { get as getAPIV2 } from "@/api/getApi2.0";
import { ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2 } from "@/api/config/api2.0";

export const getListAgencies = (companyId, axios) =>
  getAPIV2(
    {
      path: ENTITY_NAME_IN_URI_V2.AGENCIES.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI_V2.AGENCIES.name,
          value: companyId
        }
      ]
    },
    {},
    axios
  );
