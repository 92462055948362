<template>
  <div class="mt-2">
    <div class="d-flex flex-colmun">
      <label for="notifications-button">{{
        $tc("CAMPAIGN_NOTIFICATIONS.ASK_NOTIFICATIONS")
      }}</label>
      <b-form-checkbox
        v-model="enabledNotifications"
        switch
        size="lg"
        class="ml-2"
      ></b-form-checkbox>
    </div>

    <div v-if="enabledNotifications" class="mt-2">
      <div class="d-flex flex-row align-items-center">
        <div class="alert alert-solid-info align-items-center mt-3">
          <i class="fa fa-info-circle mr-3" />
          {{ $tc("CAMPAIGN_NOTIFICATIONS.KEEP_UPDATING") }}
        </div>
      </div>

      <span v-if="errorNotifications" class="text-danger">{{
        $tc("ERROR_500")
      }}</span>

      <div v-if="!isLoading" class="d-flex flex-column mt-3">
        <div
          v-for="(user, index) in emailsNotifications"
          :key="`notification_${index}`"
          class="d-flex flex-row mb-2 w-100"
        >
          <div class="d-flex flex-column w-100">
            <b-form-input
              v-model="user.email"
              @input="changeEmailNotifications($event, user)"
              placeholder="Enter an email"
              type="email"
              size="sm"
              :state="user.state"
            ></b-form-input>

            <b-form-invalid-feedback :state="user.state">
              {{ $tc("CAMPAIGN_NOTIFICATIONS.FORM.ERROR_EMAIL") }}
            </b-form-invalid-feedback>
          </div>

          <div class="container-remove-button">
            <v-btn v-if="index === 0" color="#5AC2BC" icon @click="addEmail"
              ><v-icon>mdi-plus-circle</v-icon></v-btn
            >

            <v-btn
              v-if="index !== 0"
              icon
              color="#E45B64"
              @click="removeEmail(index)"
              ><v-icon>mdi-minus-circle</v-icon></v-btn
            >
          </div>
        </div>
      </div>

      <div v-if="isLoading" class="d-flex justify-content-center mt-3">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>

    <div class="container-next-step mr-2 mt-3 pt-3 border-top">
      <button
        class="btn btn-outline-primary ml-3"
        @click="$emit('cancel')"
        :disabled="isLoading"
      >
        {{ $tc("CAMPAIGN_NOTIFICATIONS.FORM.CANCEL") }}
      </button>

      <button
        class="btn btn-primary ml-3 button-height"
        @click="submitForm"
        :disabled="isLoading"
      >
        <template v-if="!isLoading">
          {{ $tc("CAMPAIGN_NOTIFICATIONS.FORM.SUBMIT") }}
        </template>
        <b-spinner v-if="isLoading" variant="white"></b-spinner>
      </button>
    </div>
  </div>
</template>

<script>
import { createCampaignNotifications } from "@/api/campaigns/manage/setters";
import { getCampaignsUsersNotifications } from "@/api/campaigns/manage/getters";

export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    campaign: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      enabledNotifications: true,
      errorNotifications: false,
      isLoading: false,
      emailsNotifications: [{ email: "", state: false }]
    };
  },

  mounted() {
    if (this.$route.params.id) {
      this.getUsersNotifications();
    } else {
      this.changeEmailNotifications(
        this.user.email,
        this.emailsNotifications[0]
      );
    }
  },

  computed: {
    hasErrorEmailNotifications() {
      const findError = this.emailsNotifications.find(
        element => element.state === false
      );

      return findError;
    }
  },

  methods: {
    changeEmailNotifications(value, user) {
      user.email = value;
      this.checkEmail(user);
    },

    checkEmail(user) {
      const regexEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
      let state = false;

      if (regexEmail.test(user.email)) {
        state = true;
      }

      user.state = state;
    },

    addEmail() {
      this.emailsNotifications.push({ email: "", state: false });
    },

    removeEmail(index) {
      this.emailsNotifications.splice(index, 1);
    },

    async getUsersNotifications() {
      this.isLoading = true;

      try {
        const userNotifications = await getCampaignsUsersNotifications(
          this.campaign.id,
          this.axios
        );

        if (userNotifications.campaignNotifications.length === 0) {
          this.enabledNotifications = false;
        } else if (userNotifications.campaignNotifications.length > 0) {
          userNotifications.campaignNotifications.forEach(
            element => (element.state = true)
          );
          this.emailsNotifications = userNotifications.campaignNotifications;
        }
      } catch (error) {
        console.error("---USER-NOTIFICATIONS");
        console.error(error);

        if (error.response.status === 500) {
          this.emailsNotifications = [];
        }
      }

      this.isLoading = false;
    },

    async submitForm() {
      if (!this.enabledNotifications) {
        this.emailsNotifications = [];
      }

      if (!this.hasErrorEmailNotifications) {
        this.isLoading = true;
        try {
          const campaignNotification = await createCampaignNotifications(
            this.emailsNotifications,
            this.campaign.id,
            this.axios
          );

          this.$emit("submitForm", campaignNotification);
        } catch (error) {
          console.error("---ERROR-NOTIFICATIONS---");
          console.error(error);
          this.errorNotifications = true;
        }

        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.container-next-step {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.container-remove-button {
  width: 36px;
  height: 36px;
}
</style>
