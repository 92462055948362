import { ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2 } from "../config/api2.0";
import { get as getV2 } from "../getApi2.0";

export const getEpisodeFromProgram = (programId, page, limit, axios) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.PROGRAMS.path,
      ressources: [
        {
          name: programId
        },
        {
          name: ENTITY_NAME_IN_URI_V2.PROGRAMS.name
        }
      ]
    },
    {
      page,
      limit
    },
    axios
  );

export const getAllProgramsPremium = (filters, axios) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.PROGRAMS.path
    },
    filters,
    axios
  );

export const getAllProgramsWithSections = (filters, axios) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.SECTIONS.path
    },
    filters,
    axios
  );
