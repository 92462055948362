<template>
  <div>
    <div class="container-next-step">
      <button
        class="btn btn-outline-primary ml-3"
        @click="$emit('previousStep')"
      >
        {{ $tc("CAMPAIGN_CONFIG_EPISODE.PREVIOUS") }}
      </button>
      <button
        class="btn btn-primary ml-3 button-height"
        :disabled="isLoading"
        @click="submitForm"
      >
        <template v-if="!isLoading">
          {{ $tc("CAMPAIGN_CONFIG_EPISODE.SUBMIT") }}
        </template>
        <b-spinner v-if="isLoading" variant="white"></b-spinner>
      </button>
    </div>

    <b-alert :show="isShowErrors" dismissible class="mt-2" variant="danger">{{
      $tc("ERROR_500")
    }}</b-alert>

    <b-alert v-if="isOverBudget" class="mt-2" show variant="warning"
      ><span class="font-weight-bold mr-1">{{
        $tc("CAMPAIGN_CONFIG_EPISODE.WARNING")
      }}</span
      >{{ $tc("CAMPAIGN_CONFIG_EPISODE.EXCEED_BUDGET") }}</b-alert
    >

    <div class="alert alert-solid-info align-items-center mt-3">
      <i class="fa fa-info-circle mr-3" />
      {{ $tc("CAMPAIGN_CONFIG_EPISODE.PRIORITIZATION_WARING") }}
    </div>

    <b-table hover :fields="header" :items="items" fixed class="mt-3">
      <template #head(title)="data">
        <span><v-icon class="mr-1">mdi-podcast</v-icon>{{ data.label }}</span>
      </template>

      <template #head(startAt)="data">
        <span
          ><v-icon class="mr-1">mdi-calendar-range</v-icon
          >{{ data.label }}</span
        >
      </template>

      <template #head(endAt)="data">
        <span
          ><v-icon class="mr-1">mdi-calendar-range</v-icon
          >{{ data.label }}</span
        >
      </template>

      <template #head(budget)="data">
        <span
          ><v-icon class="mr-1">mdi-wallet-outline</v-icon
          >{{ data.label }}</span
        >
      </template>

      <template #head(listens)="data">
        <span
          ><v-icon class="mr-1">mdi-currency-eur</v-icon>{{ data.label }}</span
        >
      </template>

      <template #head(priority)="data">
        <span
          ><v-icon class="mr-1">mdi-chevron-double-up</v-icon
          >{{ data.label }}</span
        >
      </template>

      <template #head(artificialBudgetRatio)="data">
        <span
          ><v-icon class="mr-1">mdi-speedometer</v-icon>{{ data.label }}</span
        >
      </template>

      <template #head(actions)="data">
        <span
          ><v-icon class="mr-1">mdi-gesture-tap</v-icon>{{ data.label }}</span
        >
      </template>

      <template #head(cpl)="data">
        <span
          ><v-icon class="mr-1">mdi-currency-eur</v-icon>{{ data.label }}</span
        >
      </template>

      <template #cell(title)="data">
        <span
          v-if="data.item.episode.title"
          v-b-tooltip.hover.right="data.item.episode.title"
          >{{ data.item.episode.title.substring(0, 50) }}...</span
        >
      </template>

      <template #cell(startAt)="data">
        <b-form-datepicker
          v-model="data.item.startAt"
          :placeholder="$tc('CAMPAIGN_CONFIG_EPISODE.FORM.START_DATE')"
          :state="data.item.startAt ? true : false"
          :date-format-options="{
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          }"
          :min="getMinDate"
          :disabled="
            canChangeElement({
              inputDate: data.item.startAt,
              hasChangeStartAt: data.item.hasChangeStartAt
            })
          "
          @input="changeDate('start', data.item)"
          size="sm"
          style="height: 42px;"
          class="date-picker-episode"
        ></b-form-datepicker>
        <span
          v-if="canApplyAll(data.index, data.item)"
          class="link-apply-all"
          @click="applyAll('startAt', data.item.startAt)"
          >Apply all</span
        >
      </template>

      <template #cell(endAt)="data">
        <b-form-datepicker
          v-model="data.item.endAt"
          :placeholder="$tc('CAMPAIGN_CONFIG_EPISODE.FORM.END_DATE')"
          :state="data.item.endAt ? true : false"
          :min="data.item.startAt"
          :date-format-options="{
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          }"
          :disabled="!data.item.startAt"
          size="sm"
          style="height: 42px;"
          class="date-picker-episode"
        ></b-form-datepicker>
        <span
          v-if="canApplyAll(data.index, data.item)"
          class="link-apply-all"
          @click="applyAll('endAt', data.item.endAt)"
          >Apply all</span
        >
      </template>

      <template #cell(budget)="data">
        <b-input-group append="€" style="width: 100%;">
          <b-form-input
            v-model="data.item.budget"
            id="input-budget"
            type="number"
            :state="checkBudget(data.item.budget)"
            class="bb-font-size"
            :disabled="
              canChangeElement({
                inputDate: data.item.startAt,
                hasChangeStartAt: data.item.hasChangeStartAt
              })
            "
            @input="changeBudget"
          >
          </b-form-input>
        </b-input-group>
      </template>

      <template #cell(cpl)="data">
        <b-input-group append="€" style="width: 100%;">
          <b-form-input
            v-model="data.item.cpl"
            type="number"
            :disabled="
              canChangeElement({
                inputDate: data.item.startAt,
                hasChangeStartAt: data.item.hasChangeStartAt
              })
            "
            :state="data.item.cpl ? true : false"
            class="bb-font-size"
          >
          </b-form-input>
        </b-input-group>
      </template>

      <template #cell(priority)="data">
        <b-form-select
          v-model="data.item.priority"
          :state="data.item.priority ? true : false"
          style="height: 42px;"
        >
          <b-form-select-option
            v-for="(item, index) in priorityList"
            :value="item.value"
            :key="`priority_${index}`"
          >
            {{ item.text }}</b-form-select-option
          >
        </b-form-select>
        <span
          v-if="canApplyAll(data.index, data.item)"
          class="link-apply-all"
          @click="applyAll('priority', data.item.priority)"
          >Apply all</span
        >
      </template>

      <template #cell(artificialBudgetRatio)="data">
        <b-form-select
          v-model="data.item.artificialBudgetRatio"
          value-field="value"
          text-field="text"
          :state="true"
          style="height: 42px;"
        >
          <b-form-select-option
            v-for="(item, index) in artificialBudgetRatioOptions"
            :value="item.value"
            :key="`artificialBudgetRatio_${index}`"
          >
            {{ item.text }}</b-form-select-option
          >
        </b-form-select>
      </template>

      <template #cell(actions)="data">
        <v-btn
          icon
          color="red"
          @click="openModalDelete(data.index)"
          :disabled="
            canChangeElement({
              inputDate: data.item.startAt,
              hasChangeStartAt: data.item.hasChangeStartAt
            })
          "
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>

        <v-btn
          icon
          color="green"
          @click="duplicateEpisode(data.item)"
          :disabled="
            canChangeElement({
              inputDate: data.item.startAt,
              hasChangeStartAt: data.item.hasChangeStartAt
            })
          "
        >
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </template>
    </b-table>

    <v-dialog v-model="isDeleteEpisode" width="500" persistent>
      <v-card>
        <v-card-title class="headline">{{
          $tc("CAMPAIGN_CONFIG_EPISODE.MODAL_DELETE.TITLE")
        }}</v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <button class="btn btn-outline-primary" @click="closeModal()">
            {{ $tc("CAMPAIGN_CONFIG_EPISODE.MODAL_DELETE.CANCEL") }}
          </button>

          <button class="btn btn-primary ml-3" @click="deleteEpisode()">
            {{ $tc("CAMPAIGN_CONFIG_EPISODE.MODAL_DELETE.SUBMIT") }}
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="container-next-step mt-10">
      <button
        class="btn btn-outline-primary ml-3"
        @click="$emit('previousStep')"
      >
        {{ $tc("CAMPAIGN_CONFIG_EPISODE.PREVIOUS") }}
      </button>
      <button
        class="btn btn-primary ml-3 button-height"
        :disabled="isLoading"
        @click="submitForm"
      >
        <template v-if="!isLoading">
          {{ $tc("CAMPAIGN_CONFIG_EPISODE.SUBMIT") }}
        </template>
        <b-spinner v-if="isLoading" variant="white"></b-spinner>
      </button>
    </div>
  </div>
</template>

<script>
import {
  headerConfigEpsiode,
  headerConfigEpsioderAdmin
} from "@/components/sponsoring/headerConfig";
import { updateOrderLineForCampaign } from "@/api/campaigns/podcasts/setters";
import {
  priorityList,
  artificialBudgetRatioOptions
} from "@/common/config/campaigns/static.edit";
import i18nService from "@/common/i18n.service.js";

export default {
  name: "ConfigEpisode",

  props: {
    items: {
      episodes: Array,
      required: true
    },
    budget: {
      type: Number,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: true
    },
    campaign: {
      type: Object,
      required: false
    },
    budgetSpent: {
      type: Number,
      required: false
    }
  },

  data() {
    return {
      budgetChange: 0,
      isLoading: false,
      isCanCheck: false,
      periodApplyAll: "",
      budgetApplyAll: "",
      hasElementHighest: false,
      isShowErrors: false,
      isDeleteEpisode: false,
      indexDeleEpisode: 0
    };
  },

  watch: {
    items() {
      this.changeBudget();
    }
  },

  computed: {
    header() {
      let header = headerConfigEpsiode;

      if (this.isAdmin) {
        header.splice(header.length - 1, header, headerConfigEpsioderAdmin);
      }

      return header;
    },

    getMinDate() {
      const today = new Date();

      return `${today.getFullYear()}-${today.getMonth() +
        1}-${today.getDate()}`;
    },

    isOverBudget() {
      let isOverBudget = false;

      if (this.budgetSpent > this.budget) {
        isOverBudget = true;
      }

      return isOverBudget;
    },

    hasSelectedEspiode() {
      return this.isCanCheck ? this.items.length > 0 : true;
    },

    hasNoErrorPeriodDate() {
      const findError = this.items.find(
        element => element.startAt === true && element.endAt === true
      );
      return !findError;
    },

    priorityList() {
      return priorityList;
    },

    artificialBudgetRatioOptions() {
      return artificialBudgetRatioOptions;
    },

    activeLanguage() {
      return i18nService.getActiveLanguage();
    }
  },

  methods: {
    changeBudget() {
      this.remainingBudget();
      this.$emit("budgetSpent", this.budgetChange);
    },

    checkBudget(budget) {
      const budgetInt = parseInt(budget);

      if (this.isOverBudget) {
        return false;
      }

      if (budgetInt > 0 && this.budgetChange < 0) {
        return false;
      } else if (isNaN(budgetInt)) {
        return false;
      }

      return true;
    },

    remainingBudget() {
      this.budgetChange = 0;
      if (this.items.length > 0) {
        this.items.forEach(element => {
          if (element.budget) {
            this.budgetChange += parseFloat(
              parseFloat(element.budget).toFixed(2)
            );
          }
        });
      }
    },

    hasError() {
      const hasError = this.items.filter(element => {
        if (
          !element.startAt ||
          !element.endAt ||
          !element.cpl ||
          !element.priority ||
          !parseInt(element.budget) > 0
        ) {
          return element;
        }
      });

      return hasError.length > 0;
    },

    applyAll(field, data) {
      this.$emit("applyAll", { field, data });
    },

    canApplyAll(index, data) {
      if (this.items.length > 1 && index === 0 && data) {
        return true;
      }

      return false;
    },

    openModalDelete(index) {
      this.indexDeleEpisode = index;
      this.isDeleteEpisode = true;
    },

    closeModal() {
      this.indexDeleEpisode = 0;
      this.isDeleteEpisode = false;
    },

    deleteEpisode() {
      this.$emit("deleteEpisode", this.indexDeleEpisode);
      this.closeModal();
    },

    duplicateEpisode(episode) {
      this.$emit("duplicateEpisode", episode);
    },

    changeDate(period, item) {
      item.hasChangeStartAt = true;
      this.checkDate(period, item);
    },

    checkDate(period, item) {
      if (!period[item]) {
        item[`${period}State`] = true;
      } else {
        item[`${period}State`] = false;
      }
    },

    canChangeElement({ inputDate, hasChangeStartAt }) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      let date = new Date(inputDate);
      date.setHours(0, 0, 0, 0);

      let condition = date <= today;

      if (hasChangeStartAt) {
        condition = false;
      }

      return condition;
    },

    async submitForm() {
      this.isCanCheck = true;
      this.hasNoErrorPeriodDate;

      if (
        !this.hasError() &&
        !this.isOverBudget &&
        this.hasSelectedEspiode &&
        this.hasNoErrorPeriodDate
      ) {
        this.isLoading = true;

        let body = [];

        // Create object to send for the API
        this.items.forEach(element => {
          let object = {
            delivery: "smooth",
            cpl: element.cpl,
            type: "episode",
            budget: element.budget,
            startAt: element.startAt,
            endAt: element.endAt,
            objectif: 124000,
            priority: element.priority,
            objectifType: "cpl",
            episodeType: "manual",
            artificialBudgetRatio: element.artificialBudgetRatio
          };

          if ("author" in element) {
            object.episode = element.id;
          } else {
            object.id = element.id;
            object.episode = element.episode.id;
          }

          body.push(object);
        });

        try {
          let espisodesConfig = {};
          espisodesConfig = await updateOrderLineForCampaign(
            this.campaign.id,
            body,
            this.axios
          );

          this.$emit("submitForm", espisodesConfig);
        } catch (error) {
          console.error("---ERROR-CONFIG-EPISODES---");
          console.error(error);
          this.isShowErrors = true;
        }
        this.isLoading = false;
      }
    }
  }
};
</script>

<style>
.container-next-step {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.link-apply-all {
  font-size: 10px;
  color: blue;
  cursor: pointer;
  margin-top: 2px;
}

.b-form-btn-label-control.form-control > .btn {
  padding-right: 5%;
  padding-left: 5%;
}

.b-form-btn-label-control.form-control > .form-control.form-control-sm {
  padding-right: 0px;
  padding-left: 2px;
  padding-top: 8px;
}

.date-picker-episode {
  font-size: 13px;
  height: 36px;
}

.b-form-btn-label-control.form-control > .form-control.form-control-sm {
  padding-right: 0px !important;
}

.b-form-btn-label-control.form-control {
  padding-right: 0 !important;
}
</style>
