import { get as getAPIV2 } from "@/api/getApi2.0";
import { ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2 } from "@/api/config/api2.0";

export const getListAdvertisers = (companyId, axios) =>
  getAPIV2(
    {
      path: ENTITY_NAME_IN_URI_V2.ADVERTISERS.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI_V2.ADVERTISERS.name,
          value: companyId
        }
      ]
    },
    {},
    axios
  );
